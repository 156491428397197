import { CustomerOrder } from '~/modules/GraphQL/types';

export const getCustomerOrderTotals = (order: CustomerOrder) => {
  const isAllProductsCanceled = order.items.every((
    item,
  ) => item.quantity_ordered === (item.quantity_canceled + item.quantity_refunded));

  const grandTotal = order.total.grand_total.value;

  const total = order.items.reduce((totalAmount, item) => {
    const price = item.product_sale_price.value;
    const qty = item.quantity_ordered - (item.quantity_canceled + item.quantity_refunded);
    const discountsAmountPerItem = item.discounts.reduce((amount, discount) => amount + (((discount.amount.value / item.quantity_ordered) * 100) / 100), 0);

    return totalAmount + (price * qty - discountsAmountPerItem * qty);
  }, 0);

  const totalIncShipping = total + order.total.total_shipping.value;

  return {
    prices: {
      grandTotal,

      total,
      totalIncShipping,
    },

    state: {
      allProductsCanceled: isAllProductsCanceled,
    },
  };
};
