import dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs().locale('ru');

type GetOrderDateConfig = {
  minDate?: Date,
  maxDate?: Date,
  minDateFromToday?: number,
  maxDateFromToday?: number,
};

type GetOrderDateOptions = {
  formatTemplate?: string;
};

const DEFAULT_OPTIONS = {
  formatTemplate: 'dd, D MMMM',
};

const getFormattedDateLine = (
  minDate: dayjs.Dayjs | null = null,
  maxDate: dayjs.Dayjs | null = null,
  options?: GetOrderDateOptions,
) => {
  const minDateFormatted = minDate ? minDate.locale('ru').format(options.formatTemplate) : null;
  const maxDateFormatted = maxDate ? maxDate.locale('ru').format(options.formatTemplate) : null;

  switch (true) {
    case minDate === null && maxDate === null:
      return '';

    case minDate && !maxDate:
      return ['от', minDateFormatted].join(' ');

    case maxDate && !minDate:
      return ['до', maxDateFormatted].join(' ');

    case minDate.isSame(maxDate, 'day'):
      return minDateFormatted;

    case Boolean(minDate && maxDate):
      return [minDateFormatted, maxDateFormatted].join(' – ');

    case Boolean(minDate):
      return ['от', minDateFormatted].join(' ');

    case Boolean(maxDate):
      return ['от', maxDateFormatted].join(' ');

    default:
      return '';
  }
};

const buildDateFromConfig = (date: Date, dateFromToday: number): dayjs.Dayjs | null => {
  if (date) {
    return dayjs(date);
  }

  if (dateFromToday) {
    return dayjs().add(dateFromToday, 'day');
  }

  return null;
};

export const getOrderDate = (
  config: GetOrderDateConfig,
  options: GetOrderDateOptions = DEFAULT_OPTIONS,
) => {
  const minDate = buildDateFromConfig(config.minDate, config.minDateFromToday);
  const maxDate = buildDateFromConfig(config.maxDate, config.maxDateFromToday);

  return getFormattedDateLine(minDate, maxDate, options);
};
