import {
  computed, defineComponent, ref, unref, useContext, useFetch, useRoute, useRouter,
} from '@nuxtjs/composition-api';
import {
  SfLink, SfPrice, SfButton, SfLoader, SfNotification,
} from '@storefront-ui/vue';

import {
  CustomerOrder, CustomerOrders, useBem, useUserOrder,
} from '~/composables';

import orderGetters from '~/modules/checkout/getters/orderGetters';

import RsgOrderProduct from '~/modules/customer/components/order-product';
import { getCustomerOrderTotals } from '~/helpers/getOrderTotals';
import { getOrderDate } from '~/helpers/getOrderDate';

export default defineComponent({
  name: 'RsgCustomerOrderHistoryDetails',

  components: {
    SfLink,
    SfPrice,
    SfButton,
    SfLoader,
    SfNotification,
    RsgOrderProduct,
  },

  props: {
    orderId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const b = useBem();

    const context = useContext();
    const router = useRouter();
    const route = useRoute();

    const {
      error,
      search,
      cancel,
      showCancelOrderModal,
      loading,
      isCancelModalVisible,
    } = useUserOrder();

    const ordersRaw = ref<CustomerOrders | null>(null);

    const getOrder = async () => {
      ordersRaw.value = await search({ filter: { number: { eq: props.orderId } } });

      if (!ordersRaw.value.items[0]) {
        router.push(context.localeRoute({ name: 'customer-order-history' }));
      }
    };

    useFetch(getOrder);

    const order = computed(() => ordersRaw.value?.items[0] ?? null);

    const orderDate = computed(() => getOrderDate({
      minDate: orderGetters.getDate(unref(order)),
      maxDate: orderGetters.getDate(unref(order)),
    }, {
      formatTemplate: 'dd, D MMMM YYYY',
    }));

    const orderDeliveryMinDate = computed(() => {
      const date = orderGetters.getDeliveryMinDate(unref(order));

      if (!date) {
        return null;
      }

      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      return {
        day,
        month,
        year,
      };
    });

    const orderDeliveryMaxDate = computed(() => {
      const date = orderGetters.getDeliveryMaxDate(unref(order));

      if (!date) {
        return null;
      }

      const day = date.getDate();
      const month = date.getMonth();
      const year = date.getFullYear();

      return {
        day,
        month,
        year,
      };
    });

    const orderDeliveryDateFormatted = computed(() => getOrderDate({
      minDate: orderGetters.getDeliveryMinDate(unref(order)),
      maxDate: orderGetters.getDeliveryMaxDate(unref(order)),
    }, {
      formatTemplate: 'dd, D MMMM YYYY',
    }));

    const orderAddress = computed(() => {
      const {
        area,
        city,
        street,
        house,
        apartment,
        entrance,
        floor,
        intercom,
      } = order.value.shipping_address;

      const formattedStreet = street && street.length > 0 ? street[0].replace(' ', '&nbsp;') : '';
      const formattedApartment = apartment ? `кв./офис&nbsp;${apartment}` : '';
      const formattedEntrance = entrance ? `подъезд&nbsp;${entrance}` : '';
      const formattedFloor = floor ? `этаж&nbsp;${floor}` : '';
      const formattedIntercom = intercom ? `домофон&nbsp;${intercom}` : '';

      const firstLine = [
        area,
        city,
      ]
        .filter(Boolean)
        .join(', ');

      const secondaryLine = [
        formattedStreet,
        house,
        formattedApartment,
        formattedEntrance,
        formattedFloor,
        formattedIntercom,
      ]
        .filter(Boolean)
        .join(', ');

      return [
        firstLine,
        secondaryLine,
      ]
        .filter(Boolean)
        .join('<br/>');
    });

    const orderTracking = computed(() => order.value.shipments.flatMap((shipment) => shipment.tracking));

    const orderTotals = computed(() => getCustomerOrderTotals(order.value));

    const orderTotal = computed(() => (
      orderTotals.value.state.allProductsCanceled
        ? orderTotals.value.prices.grandTotal
        : orderTotals.value.prices.totalIncShipping
    ));

    const showProducts = ref(false);

    const onProductsShowMoreClick = () => {
      showProducts.value = !unref(showProducts);
    };

    const cancelOrder = async (cancelableOrder: CustomerOrder) => {
      await cancel(cancelableOrder);

      if (!error.value.cancel) {
        getOrder();
      }
    };

    const ordersPageLink = computed(() => {
      const currentPage = route.value.query.fromPage;
      return context.app.localeRoute({ name: 'customer-order-history', query: { page: currentPage } });
    });

    return {
      b,

      isCancelModalVisible,
      cancelOrder,
      showCancelOrderModal,
      loading,
      order,
      orderDate,
      orderDeliveryDateFormatted,
      orderDeliveryMinDate,
      orderDeliveryMaxDate,
      orderAddress,
      orderTracking,
      orderTotal,
      orderTotals,
      showProducts,
      getGrandTotal: orderGetters.getGrandTotal,
      getOrderCurrency: orderGetters.getOrderCurrency,

      onProductsShowMoreClick,

      ordersPageLink,
    };
  },
});
