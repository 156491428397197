import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import {
  SfImage, SfLink, SfPrice, SfBadge,
} from '@storefront-ui/vue';

import {
  OrderItemInterface, useBem,
} from '~/composables';

import SvgImage from '~/components/General/SvgImage.vue';
import { getOrderProductTotals } from '~/helpers/getProductTotals';

export default defineComponent({
  name: 'RsgOrderProduct',

  components: {
    SfImage,
    SfLink,
    SfPrice,
    SfBadge,
    SvgImage,
  },

  props: {
    item: {
      type: Object as PropType<OrderItemInterface>,
      required: true,
    },
  },

  setup(props) {
    const b = useBem();

    const totals = computed(() => getOrderProductTotals(props.item));

    return {
      b,

      totals,
    };
  },
});
