import { OrderItemInterface } from '~/modules/GraphQL/types';

export const getOrderProductTotals = (product: OrderItemInterface) => {
  const qtyOrdered = product.quantity_ordered;
  const qtyRefunded = product.quantity_refunded;
  const qtyCanceled = product.quantity_canceled + qtyRefunded;
  const qtyFinal = product.quantity_ordered - qtyCanceled;

  const { discounts } = product;

  const itemDiscountsAmount = discounts.reduce((amount, discount) => amount + (discount.amount.value * 100) / 100, 0);
  const itemFinalDiscountsAmount = (itemDiscountsAmount / qtyOrdered) * qtyFinal;

  const price = product.product_sale_price.value;
  const priceBase = price * qtyOrdered;
  const priceFinal = price * qtyFinal;
  const priceFinalWithDiscounts = priceFinal - itemFinalDiscountsAmount;

  const isCanceled = qtyCanceled > 0;
  const isFullCanceled = isCanceled && qtyOrdered === qtyCanceled;
  const isPartialCanceled = isCanceled && qtyOrdered !== qtyCanceled;

  return {
    qty: {
      ordered: qtyOrdered,
      canceled: qtyCanceled,
      refunded: qtyRefunded,
      final: qtyFinal,
    },

    prices: {
      base: priceBase,
      final: priceFinal,
      finalWithDiscounts: priceFinalWithDiscounts,
    },

    discounts: {
      amount: itemDiscountsAmount,
    },

    state: {
      canceled: isCanceled,
      fullCanceled: isFullCanceled,
      partialCanceled: isPartialCanceled,
    },
  };
};
